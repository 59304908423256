import { render, staticRenderFns } from "./OnePagination.vue?vue&type=template&id=7a4e8938&scoped=true&"
import script from "./OnePagination.vue?vue&type=script&lang=ts&"
export * from "./OnePagination.vue?vue&type=script&lang=ts&"
import style0 from "./OnePagination.vue?vue&type=style&index=0&id=7a4e8938&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@4.3.0_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a4e8938",
  null
  
)

export default component.exports