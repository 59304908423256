


















  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component
  export default class OnePagination extends Vue {
    name: string = 'OnePagination'

    rows: any = [
      { value: 15, text: '15' },
      { value: 30, text: '30' },
      { value: 45, text: '45' },
    ]

    @Prop({
      type: Number,
    })
    currentPage?: number

    @Prop({
      type: Number,
    })
    perPage?: number

    @Prop({
      type: Number,
    })
    totalPages?: number

    @Prop({
      type: Number,
    })
    totalItems?: number
  }
